import React from 'react';
import SlidePanel from "../slide-panel";
import * as Tools from "../../common/utils/tools";
import SonnenkernImage from './img/sonnenkern.gif';
import './InfoPanel.scss';
const packageJson = require('quip-export/package.json');

class InfoPanel extends React.Component{
    constructor(props) {
        super(props);
        this.state = { visible: false };
    }

    onAvatarClick = () => {
        this.setState((state) => {
            return  {
                visible: !state.visible
            }
        });
    };

    render() {
        console.log();
        return (
            <SlidePanel visible={this.state.visible}>
                <div className={'info-panel__wrapper'} onClick={this.onAvatarClick}>
                    <div className={'info-panel__avatar-wrapper'}>
                        <div className={'info-panel__avatar--clip'}>
                            <img title="Info" className={'info-panel__avatar'} alt="Info" src={SonnenkernImage}/>
                        </div>
                    </div>
                    <div className={'info-panel__delimeter'}></div>
                    <div className={'info-panel__content'}>
                        (c) sonnenkern &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;{Tools.emailRender('info@quip-export.com')}<br/><br/>
                        quip-export module v.{packageJson.version}<br/>
                        <a href={'https://github.com/sonnenkern/quip-export'} target={'blank'}>https://github.com/sonnenkern/quip-export</a>
                    </div>
                </div>
            </SlidePanel>
        )
    }
}

export default InfoPanel;