import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Switch from "react-switch";

const QuipExportForm = (props) => {
    const [token, setToken] = useState('');
    const [embeddedStyles, setEmbeddedStyles] = useState(false);
    const [comments, setComments] = useState(false);
    const [docx, setDocx] = useState(false);

    const onClickDemoLink = (event) => {
        //props.testFunc(3);
        event.preventDefault();
        setToken('DEMO');
        props.resetProgressor();
    };

    const onChangeToken = (event) => {
        props.resetProgressor();
        const { target: { value } } = event;
        setToken(value.trim());
    };

    useEffect(() => {
        if(docx) {
            setComments(false);
            setEmbeddedStyles(false);
        }
    }, [docx]);

    const onSubmit = () => {
        props.onStartExport(token, {docx, embeddedStyles, comments});
    };

    return  (
       <React.Fragment>
           <div className={'form-area__row form-area__row--equal-input margin-btm'}>
               To generate a <b>personal Access Token</b> for your Quip account, please visit the page: <br/>
               <a rel="noopener noreferrer" href='https://quip.com/dev/token' target='_blank'>https://quip.com/dev/token</a>
               <br/><br/>
               Demo available with the token <a href="#a" onClick={onClickDemoLink} target="_blank">DEMO</a>.
           </div>
           <div className={'form-area__row margin-btm'}>
               <input className={'form-area__input'} required type='text' name='token' readOnly={props.running}
                      value={token} onChange={onChangeToken} placeholder={'Please enter here the Quip Access Token'} />
           </div>
           <div className={'form-area__row margin-btm options'}>
               <div className={'option__item'}>
                   <div>
                       .docx / .xlsx
                   </div>
                   <div>
                       <Switch onChange={() => setDocx(!docx)} checked={docx} disabled={props.running} onColor={'#f17b01'}/>
                   </div>
               </div>
               <div className={'option__item'}>
                   <div>
                       Embedded styles
                   </div>
                   <div>
                       <Switch onChange={() => setEmbeddedStyles(!embeddedStyles)} checked={embeddedStyles} disabled={docx || props.running} onColor={'#f17b01'}/>
                   </div>
               </div>
               <div className={'option__item'}>
                   <div>
                       Comments
                   </div>
                   <div>
                       <Switch onChange={() => setComments(!comments)} checked={comments} disabled={docx || props.running} onColor={'#f17b01'}/>
                   </div>
               </div>
           </div>
           <div className={'form-area__row'}>
               <Button variant={"start"} size={'tiny'} disabled={props.running} onClick={onSubmit}>Start export</Button>
           </div>
       </React.Fragment>
    )
};

QuipExportForm.propTypes = {
    running: PropTypes.bool,
    onStartExport: PropTypes.func,
    resetProgressor: PropTypes.func
};

QuipExportForm.defaultProps = {
    running: false
};

export default QuipExportForm;


