import React from "react";
import Popup from 'react-popup';

import './ModalMessageGlobal.scss';


const ModalMessageGlobal = (props) => {
    return  <Popup/>
};

export default ModalMessageGlobal;