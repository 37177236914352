import React from "react";

import './AccentText.scss';
import PropTypes from "prop-types";

const AccentText = (props) => {
    return  (
        <div className={'accent-text'} key={props.text} style={{position:'relative', textAlign: 'center', display: 'inline-block'}}>
            <div>{props.text}</div>
            <div className="accent-text__overlay">{props.text}</div>
        </div>
    );
};

AccentText.propTypes = {
    text: PropTypes.string
};

export default AccentText;