import {LoggerAdapter} from "quip-export";
import moment from "moment";

const weight = {
    [LoggerAdapter.LEVELS.DEBUG]: 20,
    [LoggerAdapter.LEVELS.INFO]: 30,
    [LoggerAdapter.LEVELS.WARN]: 40,
    [LoggerAdapter.LEVELS.ERROR]: 50,
};

export class SimpleLogger extends LoggerAdapter {
    constructor(level=LoggerAdapter.LEVELS.INFO, destination) {
        super(level, destination);
    }

    isLevelVisible(level) {
        return weight[level] >= weight[this.level]? true : false;
    }

    log(level, message) {
        if(!this.isLevelVisible(level)) {
            return;
        }

        const printStr = `${moment().format('DD.MM.YYYY HH:mm:ss')} [${level.toUpperCase()}] ${message}`;

        if(!this.destination) {
            if(level !== LoggerAdapter.LEVELS.ERROR) {
                console.log(printStr);
            } else {
                console.error(printStr);
            }
            return;
        }

        if(typeof this.destination === 'object' && 'write' in this.destination && typeof this.destination.write === 'function') {
            this.destination.write(printStr + "\n");
        }
    }

    debug (message)  { this.log(LoggerAdapter.LEVELS.DEBUG, message); }
    info  (message)  { this.log(LoggerAdapter.LEVELS.INFO,  message); }
    warn  (message)  { this.log(LoggerAdapter.LEVELS.WARN,  message); }
    error (message)  { this.log(LoggerAdapter.LEVELS.ERROR, message); }
}

export class StringLog {
    constructor() {
        this.str = '';
    }

    write(message) {
        this.str = this.str.concat(message);
    }
}