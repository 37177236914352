import React from "react";
import InfoPanel from "../components/info-panel";

import './Page.scss';
import PropTypes from "prop-types";

const Page = (props) => {
    return  (
        <div className={'page__wrapper'}>
            <div className={'page__content'}>
                {props.children}
            </div>
            <div className={'page__footer'}>
                <InfoPanel/>
            </div>
        </div>
    )
};


Page.propTypes = {
    children: PropTypes.object
};

export default Page;