import React from 'react';
import QuipExport from "./components/quip-export";
import GitHubRibbon from "./common/components/GitHubRibbon";
import Page from "./pages/Page";
import {ModalMessageGlobal} from "./components/modal-message";
import { BrowserRouter as Router, Route} from 'react-router-dom';

import './app.scss';


const App = () => {
    return (
        <Router>
            <ModalMessageGlobal/>
            <GitHubRibbon right color={'darkblue'} url={'https://github.com/sonnenkern/quip-export'}/>
            <Page>
                <Route path="/:folders?" render={({match: {params}}) => <QuipExport {...params}/>}></Route>
            </Page>
        </Router>

    );
};

export default App;
