import React, {useEffect, useRef, useState} from 'react';
import {QuipProcessor, QuipService} from "quip-export";
import JSZip from 'jszip';
import FileSaver from "file-saver";
import openMessageDialog from "../modal-message/ModalMessageDialog";
import "react-circular-progressbar/dist/styles.css";
import './QuipExport.scss';
import Progressor from "./Progressor";
import QuipExportForm from "./QuipExportForm";

import {SimpleLogger, StringLog} from '../../common/utils/SimpleLogger';
import PropTypes from "prop-types";

const strLog = new StringLog();
const Logger = new SimpleLogger(SimpleLogger.LEVELS.INFO, strLog);

let QUIP_API_URL_DEMO = '/api/demo';
const QUIP_API_URL = '/api/quip';

let zip;
let quipProcessor;

function fileSaver(blob, fileName, type, path) {
  zip.folder(path).file(fileName, blob);
  //console.log("SAVE: ", fileName, "PATH: ", path);
}

async function getCssBlob() {
  return  await (await fetch(`/templates/document.css`)).text();
}

async function getDocumentTemplate() {
  return await (await fetch(`/templates/document.ejs`)).text();
}

async function tokenVerification(token) {
  let quipApiUrl = QUIP_API_URL;

  if(token === 'DEMO') {
    quipApiUrl = QUIP_API_URL_DEMO;
  }

  const quipService = new QuipService(token, quipApiUrl);
  return quipService.checkUser();
}

const QuipExport = (props) => {
  const progressorRef = useRef(null);

  const [progressValue, setProgressValue] = useState(0);
  const [message, setMessage] = useState('');
  const [progressText, setProgressText] = useState('');
  const [phase, setPhase] = useState('');
  const [progress, setProgress] = useState({});
  const [running, setRunning] = useState(false);

  useEffect(() => {
    /*
    effect for progress indication
  */
    if(phase === "ANALYSIS") {
      setProgressText(`Read ${progress.readFolders} folder(s) and ${progress.readThreads} thread(s)`);
    } else if(phase === "EXPORT") {
      setProgressText(`Exported ${progress.threadsProcessed} of ${progress.threadsTotal} thread(s)`);
      setProgressValue(Math.floor((progress.threadsProcessed/progress.threadsTotal)*100));
    }
  }, [progress]);

  useEffect(() => {
    /*
     effect for export life cycle phases change
     available phases:
      START - start of process
      STOP -  end of process
      ANALYSE - folder/threads stucture analyse
      EXPORT - export
   */
    if (phase === 'START'){
      resetProgressor(true);
    }

    if (phase === 'STOP'){
      setMessage('');
      setRunning(false);
    }

    if (phase === 'ANALYSIS'){
      setMessage('Analysing folders');
    }

    if(phase === 'EXPORT') {
      setMessage('Exporting');
    }
  }, [phase]);

  const saveZip = (content) => {
    FileSaver.saveAs(content, 'quip-export.zip');
    setMessage('Export successfully finished!');
  };

  const startExport = async (token, options) => {
    setRunning(true);

    const cssText = await getCssBlob();


    zip = new JSZip();

    if(!options.embeddedStyles && !options.docx) {
      zip.file('document.css', cssText);
    }

    let quipApiUrl = QUIP_API_URL;

    if(token === 'DEMO') {
      quipApiUrl = QUIP_API_URL_DEMO;
    }

    getDocumentTemplate().then((template) => {
      quipProcessor = new QuipProcessor(token, fileSaver,
          (proggr) => setProgress(proggr),
          (nextPhase, prevPhase) => setPhase(nextPhase) ,
          {
            documentTemplate: template,
            quipApiURL: quipApiUrl,
            documentCSS: options.embeddedStyles? cssText : '',
            embeddedImages: true,
            comments: options.comments,
            docx: options.docx
      });

      quipProcessor.setLogger(Logger);

      let foldersToExport = [
        //'XJXAOAeNRdL'
        //'GdFAOAxht8Y'
        //'EVZAOAW2e6U'
      ];

      if(props.folders) {
        const foldersIds = props.folders.split(',');
        foldersToExport = foldersIds.map((id) => id.trim());
      }

      quipProcessor.startExport(foldersToExport).then(() => {
        if(strLog.str) {
          zip.file('export.log', strLog.str);
          openMessageDialog(`Please look at export.log for errors and warnings!`);
        }
        zip.generateAsync({type:"blob", compression: "DEFLATE"}).then(saveZip);
      }).catch((error) => {
        console.error(error);
        setProgressText('');
        setMessage('');
        setRunning(false);
        openMessageDialog(`Server error: ${error.message}`);
      });
    });
  };

  const tokenVerificationAndStart = (token, options) => {
    if(!token) {
      openMessageDialog('Token is empty!');
      return;
    }

    tokenVerification(token).then((tokenOk) => {
      if(!tokenOk) {
        if(token === 'DEMO') {
          openMessageDialog('Demo mode is temporary not available! Please try it again later!');
        } else {
          openMessageDialog('Token is invalid or expired!');
        }
        return;
      }

      window.scrollTo(0, progressorRef.current.offsetTop);

      startExport(token, options);
    });
  };

  const resetProgressor = (running=false) => {
    setProgressText('');
    setMessage('');
    setRunning(running);
    setProgressValue(0);
  };

  const onStartExport = (token, options) => {
    resetProgressor();
    tokenVerificationAndStart(token, options);
  };

  return (
      <div className={'quip-export'}>
        <div className={'logo'}>
          Quip<span className={'logo--shift'}>Export</span>
        </div>

        <div className={'header'}>
          Comprehensive full automated export (backup) of all files and folders for your <a rel="noopener noreferrer" href={'https://quip.com'} target='_blank'>Quip account</a>.
          <br/>
        </div>
        <div className={'export-area'}>
          <div className={'form-area'}>
            <QuipExportForm running={running} onStartExport={onStartExport} resetProgressor={resetProgressor} />
          </div>
          <div className={'progressor'} ref={progressorRef}>
            <Progressor message={message} running={running} text={progressText} value={progressValue} />
          </div>
        </div>
      </div>
  );

};

QuipExport.propTypes = {
  folders: PropTypes.string
};

export default QuipExport;
