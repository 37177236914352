import React from "react";

export function objectCopyOverJSON(src) {
    return JSON.parse(JSON.stringify(src));
}

export function generateUUID() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function emailRender(email) {
    let i = 0;
    return <span>
        {
            email.split('').map((el) => {
                return <span key={i++}>{el}</span>;
            })
        }
    </span>;
}