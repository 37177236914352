import React from "react";
import PropTypes from 'prop-types';

/*
colors: red, green, darkblue, orange, gray, white
 */
function getRibbonImage(left, right, color) {
    const colors = {
        red: 'red_aa0000',
        green: 'green_007200',
        darkblue: 'darkblue_121621',
        orange: 'orange_ff7600',
        gray: 'gray_6d6d6d',
        white: 'white_ffffff'
    };

    let direction = 'right';
    if(left) {
        direction = 'left';
    }

    let selectedColor;

    for(let indx in colors) {
        if(indx === color) {
            selectedColor = colors[indx];
            break;
        }
    }

    if(!selectedColor) {
        throw new Error(`Github-Ribbon color '${color}' is wrong!`);
    }

    return `forkme_${direction}_${selectedColor}.png`;
}

const GitHubRibbon = (props) => {
    let imageSrc;
    try {
        imageSrc = `https://github.blog/wp-content/uploads/2008/12/${getRibbonImage(props.left, props.right, props.color)}?resize=149%2C149`;
    }catch (e) {
        console.error(e);
        return "";
    }

    const style = {
        position: 'absolute',
        top: 0,
        border: 0,
        zIndex: 1000
    };

    if(props.left) {
        style.left = 0;
    } else {
        style.right = 0;
    }

    return  (
        <a href={props.url} target={'blank'}>
            <img width="149" height="149" style={style}
                 src={imageSrc} alt="Fork me on GitHub" data-recalc-dims="1"/>
        </a>
    );
};

GitHubRibbon.propTypes = {
    left: PropTypes.bool,
    right: PropTypes.bool,
    color: PropTypes.string,
    url:  PropTypes.string
};

GitHubRibbon.defaultProps = {
    left: false,
    right: true,
    color: 'gray'
};

export default GitHubRibbon;