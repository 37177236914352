import React from 'react';
import classNames from 'classnames';
import PropTypes from "prop-types";

import './SlidePanel.scss';


const SlidePanel = (props) => {
    const className = classNames('slide-panel__slide', {'slide-panel__slide--move': props.visible});
    return  (
        <div className={'slide-panel__wrapper'}>
            <div className={className}>
                {props.children}
            </div>
        </div>
    )
};


SlidePanel.propTypes = {
    visible: PropTypes.bool,
    children: PropTypes.object
};

SlidePanel.defaultProps = {
    visible: false
};

export default SlidePanel;