import './ModalMessageDialog.scss';
import Popup from "react-popup/dist";


const openMessageDialog = (message) => {
    Popup.create({
        title: null,
        content: message,
        buttons: {
            right: ['cancel']
        }
    }, true);
};

export default openMessageDialog;