import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import Spinner from "react-spinners/BeatLoader";
import AccentText from "../accent-text";
import React from "react";
import PropTypes from "prop-types";
import GitHubButton from "react-github-btn";

const Progressor = (props) => {
    const showGithubButton = !props.running && !props.value;

    return  (
        <CircularProgressbarWithChildren
            value={props.value}
            strokeWidth={2}
            styles={buildStyles({
                pathColor: "#f17b01",
                trailColor: "rgb(255,255,255, 0.1)"
            })}>
            <div className={'star'} style={{display: showGithubButton ? 'inline' : 'none'}}>
                If you like the app, <br/>
                please give me a star on GitHub.<br/>
                THNX! 😊
                <br/><br/>
                <GitHubButton href="https://github.com/sonnenkern/quip-export"
                              data-color-scheme="no-preference: light; light: light; dark: light;"
                              data-size="small" data-show-count="true" data-icon={'octicon-star'}
                              aria-label="Star sonnenkern/quip-export on GitHub">Star me!</GitHubButton>

            </div>
            <div className={'progressor__area'} style={{display: showGithubButton ? 'none' : 'flex'}}>
                <div className={'progressor__spinner'} style={{visibility: props.running ? 'visible' : 'hidden'}}>
                    <Spinner sizeUnit={"px"} size={20} color={'white'} loading={true}/>
                </div>
                <div className={'progressor__message'}>
                    <AccentText text={props.message}/>
                </div>
                <div className={'progressor__text'}>
                    {props.text}
                </div>
                <div className={'progressor__value'}>
                    {props.value > 0 && `${props.value} %`}
                </div>
            </div>

        </CircularProgressbarWithChildren>
    )
};

Progressor.propTypes = {
    value: PropTypes.number,
    running: PropTypes.bool,
    message: PropTypes.string,
    text: PropTypes.string
};

Progressor.defaultProps = {
    value: 0,
    running: false,
    message: '',
    text: ''
};

export default Progressor;


